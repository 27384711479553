import classNames from 'classnames'
import React from 'react'
import cl from '../../../classnames.scss'
import {DH} from '../../../../constants/data-hooks'
import s from './description.scss'
import {DescriptionProps} from '.'

export const Description = ({description, descriptionVisible, isSideBySideHeaderLayout}: DescriptionProps) => {
  return descriptionVisible ? (
    <div className={s.descriptionSpacing}>
      <div
        className={classNames(s.eventDescription, cl.evDescriptionFont, cl.evDescriptionColor, {
          [s.sideBySide]: isSideBySideHeaderLayout,
        })}
        data-hook={DH.headerEventDescription}
      >
        {description}
      </div>
    </div>
  ) : null
}
