import React from 'react'
import {QuantityPicker} from '../../quantity-picker'
import s from './quantity.scss'
import {QuantityProps} from '.'

export const Quantity: React.FC<QuantityProps> = ({
  selectedQuantity,
  availableTicketsQuantity,
  limit,
  disabled,
  onChange,
  id,
  t,
  isMobile,
}) => (
  <div>
    <div className={s.quantity}>
      <QuantityPicker
        quantity={selectedQuantity}
        min={0}
        max={Math.min(availableTicketsQuantity + selectedQuantity, limit)}
        disabled={disabled}
        onChange={(value) => onChange(id, value)}
        t={t}
        isMobile={isMobile}
      />
    </div>
  </div>
)
