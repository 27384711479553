import {isAssignedTickets, getFormMessages} from '@wix/wix-events-commons-statics'
import {isTemplate} from '../../../commons/selectors/instance'
import {connect} from '../runtime-context/connect'
import {getInvoice} from '../../selectors/placed-order'
import {DetailsPageAppProps} from '../app/interfaces'
import {isMobile} from '../../../commons/selectors/environment'
import {hasAgreedWithPolicies, hasPolicies} from '../../selectors/policies'
import {isAllTicketDetailsValid} from '../../selectors/checkout'
import {Checkout as CheckoutPresentation} from './checkout'
import {CheckoutOwnProps, CheckoutRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {
    placeOrderButtonClicked,
    submitCheckoutStep,
    editStep,
    setValidPaymentAdded,
    paymentMethodSelected,
    setExpandedTicketIndex,
    handleNextStep,
  },
}: DetailsPageAppProps): CheckoutRuntimeProps => {
  const {validPaymentAdded, currentStep, buyerDetails} = state.checkout
  const event = state.event
  return {
    placedOrder: state.placedOrder,
    invoice: getInvoice(state),
    validPaymentAdded,
    currentStep,
    assignedTicketsEnabled: isAssignedTickets(event),
    buyerDetails,
    isMobile: isMobile(state),
    agreedWithPolicies: hasAgreedWithPolicies(state),
    hasPolicies: hasPolicies(state),
    allTicketDetailsValid: isAllTicketDetailsValid(state),
    isTemplate: isTemplate(state),
    messages: getFormMessages(event),
    placeOrderButtonClicked,
    submitCheckoutStep,
    editStep,
    setValidPaymentAdded,
    paymentMethodSelected,
    setExpandedTicketIndex,
    handleNextStep,
  }
}

export const Checkout = connect<CheckoutOwnProps, CheckoutRuntimeProps>(mapRuntime)(CheckoutPresentation)
export * from './interfaces'
