/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface GridAlignmentArrowProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const GridAlignmentArrow: React.FC<GridAlignmentArrowProps> = ({size, ...props}) => (
  <svg viewBox="0 0 7 7" fill="currentColor" width={ size || "7" } height={ size || "7" } {...props}>
    <path d="M112.707107,58 L116,58 L116,57 L111.5,57 L111,57 L111,62 L112,62 L112,58.7071068 L117.146447,63.8535534 L117.853553,63.1464466 L112.707107,58 Z" transform="translate(-111 -57)"
      fill="currentColor" fillRule="evenodd" />
  </svg>
);
GridAlignmentArrow.displayName = 'GridAlignmentArrow';
export default GridAlignmentArrow;
/* tslint:enable */
/* eslint-enable */
