/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CheckmarkProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Checkmark: React.FC<CheckmarkProps> = ({size, ...props}) => (
  <svg viewBox="0 0 13.53 11.22" fill="currentColor" width={ size || "12" } height={ size || "9" } {...props}>
    <path fill="currentColor" d="M4.74 11.22L0 5.29 1.56 4.04 4.82 8.11 12.03 0 13.53 1.33 4.74 11.22z" />
  </svg>
);
Checkmark.displayName = 'Checkmark';
export default Checkmark;
/* tslint:enable */
/* eslint-enable */
