import classNames from 'classnames'
import React from 'react'
import {DH} from '../../constants/data-hooks'
import {Form} from '../form/container'
import c from '../classnames.scss'
import s from './buyer-details.scss'
import {BuyerDetailsProps} from './interfaces'

export const BuyerDetails = ({
  editing,
  details,
  initialValues,
  disabledFields,
  assignedTicketsEnabled,
  onSubmit,
  t,
}: BuyerDetailsProps) =>
  editing ? (
    <div className={s.container}>
      <Form
        initialValues={initialValues}
        disabledFields={disabledFields}
        hideAdditionalFields={assignedTicketsEnabled}
        onFormSubmit={onSubmit}
        t={t}
      />
    </div>
  ) : (
    <div data-hook={DH.CHECKOUT_BUYER_DETAILS} className={classNames(s.details, c.formLabelsFont, c.formTitleColor)}>
      <div>
        {details.firstName} {details.lastName}
      </div>
      <div>{details.email}</div>
    </div>
  )
