import classNames from 'classnames'
import React from 'react'
import {DH} from '../../../constants/data-hooks'
import {getFormClassName} from '../../../selectors/settings'
import {FormButton} from '../../form-button'
import fs from '../../form/form.scss'
import {FormSteps, Step} from '../../form-steps'
import {CouponInput} from '../../coupon/coupon-input'
import {Checkout} from '../index'
import {MembershipSelector} from '../membership-selector'
import {ReservationTimer} from '../reservation-timer'
import c from '../../classnames.scss'
import {CheckoutSummary} from './checkout-summary'
import {MobileCheckoutProps} from './interfaces'
import s from './mobile-checkout.scss'

export class MobileCheckout extends React.Component<MobileCheckoutProps> {
  componentDidMount() {
    this.props.formPageLoaded(true)
  }

  isCheckoutReady = (steps: Step[]) => steps.every((step) => step.completed)

  openTicketDetails = () => this.props.openTicketsDetailsModal()

  render() {
    const {buttonStyle, reservationExpiration, invoice, t, isMobile, openTimeExpiredModal} = this.props

    return (
      <Checkout t={t}>
        {(steps: Step[], currentStep: string, pay: Function) => (
          <div
            data-hook="stuff-for-test"
            className={classNames(s.container, c.formBackgroundColor, getFormClassName(buttonStyle))}
          >
            <div className={fs.summaryBlock}>
              <ReservationTimer
                expires={reservationExpiration}
                onExpire={openTimeExpiredModal}
                t={t}
                isMobile={isMobile}
              />
            </div>
            <CouponInput t={t} />
            <MembershipSelector />
            <CheckoutSummary invoice={invoice} onViewAllItems={this.openTicketDetails} t={t} />
            <FormSteps currentStep={currentStep} steps={steps} t={t} />
            {this.isCheckoutReady(steps) && (
              <div className={s.button}>
                <FormButton
                  text={t('mobile.checkout.placeOrder')}
                  buttonStyle={buttonStyle}
                  type="submit"
                  dataHook={DH.CHECKOUT_BUTTON}
                  onClick={pay}
                />
              </div>
            )}
          </div>
        )}
      </Checkout>
    )
  }
}
