import {getEventId} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/connect'
import {getReservationId} from '../../../selectors/reservation'
import {DetailsPageAppProps} from '../../app/interfaces'
import {isMobile, getLanguage, isSite, isPreview, isPrimaryLanguage} from '../../../../commons/selectors/environment'
import {getFormButtonStyle} from '../../../selectors/settings'
import {isCurrentUserLoggedIn} from '../../../selectors/current-user'
import {PaymentOwnProps, PaymentRuntimeProps} from './interfaces'
import {Payment as PaymentPresentation} from './payment'

const mapRuntime = ({
  state,
  actions: {openCantCompletePaymentModal, navigateToOrder},
  host,
}: DetailsPageAppProps): PaymentRuntimeProps => {
  const {instanceId, visitorId, msid, siteOwnerId, instance} = state.instance
  return {
    eventId: getEventId(state.event),
    order: state.placedOrder.order,
    reservationId: getReservationId(state),
    buttonStyle: getFormButtonStyle(state.component.settings),
    isMobile: isMobile(state),
    locale: getLanguage(state),
    isSite: isSite(state),
    isPreview: isPreview(state),
    primaryLanguage: isPrimaryLanguage(state),
    instanceId,
    visitorId,
    msid,
    siteOwnerId,
    openCantCompletePaymentModal,
    navigateToOrder,
    lang: state.multilingual?.currentLanguage,
    instance,
    siteStyles: host.style,
    isSignedInUser: isCurrentUserLoggedIn(state),
    updateLayout: host.updateLayout,
  }
}

export const Payment = connect<PaymentOwnProps, PaymentRuntimeProps>(mapRuntime)(PaymentPresentation)
