/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface QuestionProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Question: React.FC<QuestionProps> = ({size, ...props}) => (
  <svg viewBox="0 0 18 18" fill="currentColor" width={ size || "18" } height={ size || "18" } {...props}>
    <path d="M11.207 5.047a2.733 2.733 0 0 0-.9-.51 3.37 3.37 0 0 0-1.1-.175 3.62 3.62 0 0 0-1.354.238 2.725 2.725 0 0 0-.994.665 2.973 2.973 0 0 0-.617 1.043 2.464 2.464 0 0 0-.217 1.358h.882a5.152 5.152 0 0 1 .126-1.015 2.333 2.333 0 0 1 .42-.808 1.947 1.947 0 0 1 .708-.542 2.378 2.378 0 0 1 1-.2 2.13 2.13 0 0 1 .77.14 2.037 2.037 0 0 1 .637.385 1.8 1.8 0 0 1 .43.6 1.822 1.822 0 0 1 .16.77 1.95 1.95 0 0 1-.258.994 3.484 3.484 0 0 1-.65.81q-.446.407-.752.73a3.435 3.435 0 0 0-.5.664 1.488 1.488 0 0 0-.28.586 5.89 5.89 0 0 0-.07.887h.876c.01 0 .028-.558.056-.782a1.2 1.2 0 0 1 .168-.485 1.67 1.67 0 0 1 .378-.433c.168-.16.4-.362.7-.66A5.4 5.4 0 0 0 11.7 8.27a2.43 2.43 0 0 0 .343-1.324 2.54 2.54 0 0 0-.224-1.082 2.382 2.382 0 0 0-.613-.817zm-2.665 7.995L8.5 14.57h1.18L9.625 13zM9 0a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 17a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8z"
      fillRule="evenodd" />
  </svg>
);
Question.displayName = 'Question';
export default Question;
/* tslint:enable */
/* eslint-enable */
