import {withTranslation} from 'react-i18next'
import {getImageRatio, isSquareImage} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {MobileImageOwnProps, MobileImageRuntimeProps} from './interfaces'
import {MobileImage as Presentation} from './mobile-image'

const mapRuntime = ({
  state: {
    component: {settings},
  },
}: DetailsPageAppProps): MobileImageRuntimeProps => ({
  imageRatio: getImageRatio(settings),
  isSquareImage: isSquareImage(settings),
})

export const MobileImage = connect<MobileImageOwnProps, MobileImageRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)

export * from './interfaces'
