import {BI_ORIGINS, focusElement} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {DH, hookToAttributeSelector} from '../../constants/data-hooks'
import c from '../classnames.scss'
import {AboutSection} from '../event-details/about-section'
import {getAlignmentClass} from '../event-details/alignment'
import {FullDate} from '../event-details/full-date'
import {FullLocation} from '../event-details/full-location'
import {Map} from '../event-details/map'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import {RegistrationButton} from '../event-details/registration-button'
import {Schedule} from '../event-details/schedule'
import s from './event-details-mobile.scss'
import {MobileImage} from './mobile-image'
import {Description} from './description'
import {EventDetailsProps, ContainerProps} from '.'

export const EventDetailsMobile = ({
  eventTitle,
  timeAndLocationTitleText,
  headerAlignment,
  contentAlignment,
  membersVisible,
  t,
  isSecondRegistrationButtonVisible,
  mapVisible,
  aboutSectionVisible,
  socialShareVisible,
  scheduleVisible,
  sideBySideHeaderLayout,
  descriptionVisible,
  detailsPageLoaded,
  handleRSVP,
  mainImage,
  eventDescription,
}: EventDetailsProps) => {
  React.useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails)})
  }, [])

  const isImageVisible = () => Boolean(mainImage)

  const rsvp = () => handleRSVP()

  return (
    <section data-hook={DH.eventDetails} className={s.view} aria-label={eventTitle}>
      <div className={classNames(s.background, c.evBackgroundColor)} />
      <div className={classNames({[s.borderWrapper]: sideBySideHeaderLayout})}>
        {isImageVisible() ? <MobileImage /> : null}
        <div className={s.header}>
          <div className={classNames(s.headerBackground)} />
          <div className={s.content}>
            <div className={getAlignmentClass(headerAlignment)}>
              <h1 className={classNames(s.title, c.evTitleFont, c.evTitleColor)} data-hook={DH.headerEventTitle}>
                {eventTitle}
              </h1>
              {descriptionVisible ? <Description description={eventDescription} /> : null}
              <Subtitle
                dataHook={DH.subtitleTimeAndLocation}
                text={timeAndLocationTitleText}
                customColor={c.evDescriptionColor}
              />
              <div className={classNames(s.paragraph, c.evTextFont, c.evDescriptionColor)}>
                <FullDate />
                <FullLocation />
              </div>
              {membersVisible ? (
                <div className={s.members}>
                  <Members />
                </div>
              ) : null}
            </div>
          </div>
          <RegistrationButton onClick={rsvp} />
        </div>
      </div>
      <Container visible={aboutSectionVisible} contentAlignment={contentAlignment}>
        <AboutSection />
      </Container>
      <Container visible={scheduleVisible} contentAlignment={contentAlignment}>
        <Schedule t={t} />
      </Container>
      {isSecondRegistrationButtonVisible ? <RegistrationButton onClick={rsvp} secondary /> : null}
      {mapVisible && (
        <div className={s.mapSpacing}>
          <Map />
        </div>
      )}
      <Container visible={socialShareVisible} contentAlignment={contentAlignment}>
        <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
      </Container>
    </section>
  )
}

const Container: React.FC<ContainerProps> = ({visible, contentAlignment, children}) =>
  visible ? <div className={`${s.content} ${getAlignmentClass(contentAlignment)}`}>{children}</div> : null
