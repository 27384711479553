import classNames from 'classnames'
import React from 'react'
import {getFormClassName} from '../../../selectors/settings'
import fs from '../../form/form.scss'
import {RegistrationLayout} from '../../registration-layout'
import {FormSteps, Step} from '../../form-steps'
import {Checkout} from '..'
import {ReservationTimer} from '../reservation-timer'
import {CouponInput} from '../../coupon/coupon-input'
import {Summary} from '../../summary'
import c from '../../classnames.scss'
import {MembershipSelector} from '../membership-selector'
import {DesktopCheckoutProps} from './interfaces'

export const DesktopCheckout = ({
  buttonStyle,
  reservationExpiration,
  t,
  isMobile,
  openTimeExpiredModal,
  formPageLoaded,
}: DesktopCheckoutProps) => {
  React.useEffect(() => {
    formPageLoaded(true)
  }, [])

  return (
    <Checkout t={t}>
      {(steps: Step[], currentStep: string) => (
        <div className={classNames(c.formBackgroundColor, getFormClassName(buttonStyle))}>
          <RegistrationLayout
            Left={<FormSteps currentStep={currentStep} steps={steps} t={t} />}
            Right={
              <>
                <div className={fs.summaryBlock}>
                  <ReservationTimer
                    expires={reservationExpiration}
                    onExpire={openTimeExpiredModal}
                    t={t}
                    isMobile={isMobile}
                  />
                </div>
                <CouponInput t={t} />
                <MembershipSelector />
                <Summary t={t} />
              </>
            }
          />
        </div>
      )}
    </Checkout>
  )
}
