import classNames from 'classnames'
import React from 'react'
import s from './fixed-height-column.scss'
import {FixedHeightColumnProps, FixedHeightColumnState} from './interfaces'

export class FixedHeightColumn extends React.Component<FixedHeightColumnProps, FixedHeightColumnState> {
  state: FixedHeightColumnState = {
    height: null,
  }
  el: HTMLDivElement

  componentDidMount() {
    this.updateHeight()
  }

  componentDidUpdate() {
    this.updateHeight()
  }

  updateHeight = () => {
    const currentHeight = this.el.clientHeight
    const knownHeight = this.state.height

    if (!this.props.expanded && knownHeight !== currentHeight) {
      this.setState({height: currentHeight})
    }
  }

  render() {
    const {expanded, className, children} = this.props
    const {height} = this.state
    const style = expanded && height ? {height: `${height}px`} : {}

    return (
      <div style={style} className={classNames(s.fixedHeightColumn, className)} ref={(el) => (this.el = el)}>
        {children}
      </div>
    )
  }
}
