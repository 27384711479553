import {getEventDescription} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {isDescriptionVisible} from '../../../../selectors/event'
import {connect} from '../../../runtime-context/connect'
import {isSideBySideHeaderLayout} from '../../../../selectors/settings'
import {Description as DescriptionPresentation} from './description'
import {DescriptionRuntimeProps, DescriptionOwnProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): DescriptionRuntimeProps => ({
  description: getEventDescription(state.event),
  descriptionVisible: isDescriptionVisible(state),
  isSideBySideHeaderLayout: isSideBySideHeaderLayout(state.component.settings),
})

export const Description = connect<DescriptionOwnProps, DescriptionRuntimeProps>(mapRuntime)(DescriptionPresentation)
export * from './interfaces'
