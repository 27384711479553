import {connect} from 'react-redux'
import {untouch} from 'redux-form'
import {connect as connectRuntime} from '../../../runtime-context/connect'
import {REGISTRATION_FORM_ID} from '../../constants'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {isMobile} from '../../../../../commons/selectors/environment'
import {Name as Presentation} from './name'
import {NameDispatchProps, NameOwnProps, NameRuntimeProps, NameReduxProps} from './interfaces'

const mapDispatch = (dispatch) => ({
  untouch: () => dispatch(untouch(REGISTRATION_FORM_ID, 'firstName')),
})

const mapRuntime = ({state}: DetailsPageAppProps) => ({
  mobile: isMobile(state),
})

export const Name = connectRuntime<NameReduxProps, NameRuntimeProps>(mapRuntime)(
  connect<null, NameDispatchProps, NameOwnProps>(null, mapDispatch)(Presentation),
)

export * from './interfaces'
