import classNames from 'classnames'
import React from 'react'
import cl from '../../../classnames.scss'
import {DH} from '../../../../constants/data-hooks'
import {TitleProps} from '.'

export const Title: React.FC<TitleProps> = ({title, className}) => {
  return (
    <h1 className={classNames(className, cl.evTitleColor, cl.evTitleFont)} data-hook={DH.headerEventTitle}>
      {title}
    </h1>
  )
}
