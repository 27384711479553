import {getButtonStyle, getTexts, isHollowButton, isRoundedButton} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {isMobile} from '../../../../commons/selectors/environment'
import {getScheduleItems, getScheduleItemsTotal} from '../../../selectors/schedule'
import {isScheduleUpdatedIndicationEnabled} from '../../../../commons/selectors/experiments'
import {Schedule as Presentation} from './schedule'
import {ScheduleRuntimeProps, ScheduleOwnProps} from './interfaces'

export const mapRuntime = ({state, actions: {navigateToSchedulePage}}: DetailsPageAppProps): ScheduleRuntimeProps => {
  const componentSettings = state.component.settings
  const buttonStyle = getButtonStyle(componentSettings)

  return {
    items: getScheduleItems(state),
    total: getScheduleItemsTotal(state),
    hollowButton: isHollowButton(buttonStyle),
    roundedButton: isRoundedButton(buttonStyle),
    mobile: isMobile(state),
    scheduleTitleText: getTexts(componentSettings).scheduleTitleText,
    navigateToSchedulePage,
    scheduleUpdatedIndicationEnabled: isScheduleUpdatedIndicationEnabled(state.experiments),
  }
}

export const Schedule = connect<ScheduleOwnProps, ScheduleRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
