import classNames from 'classnames'
import React from 'react'
import {DH} from '../../../../constants/data-hooks'
import {getAlignmentClass} from '../../alignment'
import {RegistrationButton} from '../../registration-button'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import {Description} from '../description'
import s from './classic-header.scss'
import {Image} from './image'
import {ClassicHeaderLayoutProps} from './index'

export const ClassicLayout: React.FC<ClassicHeaderLayoutProps> = ({
  headerAlignment,
  isSquareImage,
  imageVisible,
  onClickRegistrationButton,
}) => {
  return (
    <div className={classNames(s.wrapper, imageVisible && (isSquareImage ? s.squareImage : s.wideImage))}>
      <div className={s.headerBackground} />
      <div className={s.container} data-hook={DH.header}>
        <div
          className={classNames(s.content, s.classicHeaderLayout, getAlignmentClass(headerAlignment), {
            [s.contentWithImage]: imageVisible,
          })}
        >
          <ShortDateLocation />
          <Title className={s.eventTitle} />
          <Description />
          <RegistrationButton onClick={onClickRegistrationButton} />
        </div>
        <Image />
      </div>
    </div>
  )
}
