import {getEventTitle} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {Title as TitlePresentation} from './title'

export interface TitleOwnProps {
  className?: string
}
export interface TitleRuntimeProps {
  title: string
}
export interface TitleProps extends TitleOwnProps, TitleRuntimeProps {}

const mapRuntime = ({state}: DetailsPageAppProps): TitleRuntimeProps => ({
  title: getEventTitle(state.event),
})

export const Title = connect<TitleOwnProps, TitleRuntimeProps>(mapRuntime)(TitlePresentation)
